import styled from "styled-components";

export const Labels = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
`;
export const LabelItem = styled.div`
  width: 48px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.colors.black};
  background: ${({ theme }): string => theme.colors.white};
  margin-top: 3px;
  font-size: 14px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      font-size: 12px;
      width: 40px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      font-size: 12px;
      width: 40px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      margin-left: 7px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }
`;
export const LabelItemSale = styled.div`
  width: 48px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.colors.black};
  background: #b1fb00;
  margin-top: 3px;
  font-size: 14px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      font-size: 12px;
      width: 40px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      font-size: 12px;
      width: 40px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      margin-left: 7px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }
`;

export const LabelItemBlack = styled.div`
  width: 100px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.white};
  background: #000;
  margin-top: 3px;
  font-size: 14px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      width: 100px;
      font-size: 12px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 70px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      width: 100px;
      font-size: 12px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 70px;
      margin-left: 7px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }
`;

export const LabelItemLove = styled.div`
  width: 48px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.colors.black};
  background-color: #ff6687;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      font-size: 12px;
      width: 40px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      font-size: 12px;
      width: 40px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      font-size: 9px;
      margin-left: 7px;
      width: 33px;
      height: 17px;
      line-height: 20px;
    }
  }
`;
export const LabelItemOrange = styled.div`
  width: 116px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ theme }): string => theme.colors.orange};
  margin-top: 3px;
  font-size: 14px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      width: 100px;
      font-size: 12px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 70px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      width: 100px;
      font-size: 12px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 70px;
      margin-left: 7px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }
`;

export const LabelItemPromo = styled.div`
  padding: 0 5px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.white};
  background: #000;
  margin-top: 3px;
  font-size: 12px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      /*width: 100px;*/
      font-size: 12px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      /*width: 70px;*/
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      /*width: 100px;*/
      font-size: 12px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      /*width: 70px;*/
      margin-left: 7px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }
`;

export const LabelItemBlackSmall = styled.div`
  width: 52px;
  height: 24px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.colors.white};
  background: #000;
  margin-top: 3px;
  font-size: 14px;

  &.main {
    @media screen and (min-width: 450px) and (max-width: 767px) {
      width: 46px;
      font-size: 12px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 46px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }

  &.also {
    @media screen and (min-width: 450px) {
      width: 46px;
      font-size: 12px;
      margin-left: 7px;
      height: 19px;
      line-height: 20px;
    }

    @media screen and (max-width: 449px) {
      width: 46px;
      margin-left: 7px;
      font-size: 9px;
      height: 17px;
      line-height: 20px;
    }
  }
`;
