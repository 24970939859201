import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  FC,
  useEffect,
  Fragment,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { setCheckout, setCurrentWorkFlow } from "src/actions";
import { setWishlistUuid, setWishlistNoUuid } from "src/actions/wishlist";
import {
  getCheckout,
  addToCheckout,
  addFavoriteProductWithBasketUuid,
  getProductQuantity,
  addLastViewsProductsByUuid,
  addToCheckoutWithoutUuid,
} from "src/api";
import {
  Accordeon,
  Article,
  FastOrder,
  ImageModal,
  ProductDelivery,
  TableSizesModal,
  ProductCategories,
  Icons,
} from "src/components";
import { HeartIcon } from "src/components/FavoriteButton";
import { Loader } from "src/components/Loader";
import ProductNotAvailableModal from "src/components/ProductNotAvailableModal";
import { ROUTES } from "src/constants";
import { CheckoutContext } from "src/context/CheckoutContext";
import { useControl } from "src/hooks";
import { ApplicationStore } from "src/store";
import {
  LocalStorageFavoriteItem,
  ProductOffer,
  ProductSizes,
  SectionTree,
} from "src/types";
import {
  formatCurrency,
  parseImages,
  convertImageUrl,
  getBasketId,
  getLastViewsFromLocalStorage,
  setLastViewsToLocalStorage,
  getFavoritesFromLocalStorage,
  setFavoritesToLocalStorage,
  sendTagManagerFavoritesData,
  sendTagManagerAddToCheckoutData,
  setBasketId,
  updateUser,
} from "src/utils";

import ProductColorContainer from "../ProductColorContainer/ProductColorContainer";
import {
  LabelItem,
  LabelItemBlack,
  LabelItemBlackSmall,
  LabelItemLove,
  LabelItemPromo,
  LabelItemSale,
  Labels,
} from "../ProductLabel/ProductLabel.styles";

import {
  Container,
  ImageContainer,
  ImageCarousel,
  ImageButton,
  Image,
  InfoContainer,
  Kind,
  NameContainer,
  Name,
  PriceContainer,
  OldPrice,
  Price,
  Promotion,
  PromotionMobile,
  AvailabilityContainer,
  AvailabilityItem,
  AvailabilityList,
  AvailabilityTitle,
  AvailabilityCount,
  AvailabilityLink,
  ButtonContainer,
  CheckoutButton,
  SizeContainer,
  SizeButtonContainer,
  SizeButton,
  HeaderContainer,
  MobileHeaderContainer,
  Picture,
  EmptyButton,
  ShowTableSizes,
  QuickOrder,
  BreadCrumbs,
  Slash,
  CategoryName,
  BreadCrumbsMobile,
  PrevArrow,
  StickyContainer,
  BrandContainer,
  FavoriteButtonProduct,
  SizeTitle,
  ImageContainerWrapper,
  Description,
  DescriptionWrapper,
  AvailabilityInnerWrapper,
  AvailabilitySize,
  ProductCategoriesWrapper,
  ShareButton,
  ButtonsContainer,
} from "./Product.styles";
import { ProductProps } from "./Product.types";
import {
  checkSizeAvailability,
  filterAvailableOffersByQuantity,
} from "./Product.utils";

const Product: FC<ProductProps> = (props) => {
  const { isInBasket, isInFavourite, onChooseSize, item, deliveryInfo } = props;
  const {
    product,
    sizes,
    sizes_table,
    section_tree,
    action,
    another_colors,
    links,
    is_msk,
  } = props.item;
  const {
    id,
    gallery,
    name,
    article,
    new_price,
    old_price,
    offers,
    type: productType,
    brand,
    detail_text,
    preview_text,
    is_new,
    is_archive,
    is_super,
    is_blacksale,
    is_finalsale,
    is_promo,
  } = product;
  const catalogClassName = "productPage";
  const categories = section_tree?.map((category) => category.name).join("/");
  const { userWorkFlow, info } = useSelector(
    (state: ApplicationStore) => state.user
  );
  const checkout = useSelector((state: ApplicationStore) => state.checkout);

  const { items } = useSelector((state: ApplicationStore) => state.checkout);
  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const [availableOffers, setAvailableOffers] = useState(offers);

  const isAvailable = useMemo(
    () => Boolean(availableOffers.find((item) => item.available)),
    [availableOffers]
  );

  const images: string[] = useMemo(() => parseImages(gallery), [gallery]);
  // const desktopImages = useMemo(() => images.slice(0, 4), [images]);
  const desktopImages = images;

  const dispatch = useDispatch();

  const checkoutId = useMemo(() => getBasketId() || info?.checkoutId, [info]);

  const [isLoading, setLoading] = useState(false);

  const {
    state: isImageOpened,
    deactivate: closeImage,
    activate: openImage,
  } = useControl();

  const {
    state: isTableSizesOpened,
    deactivate: closeTableSizes,
    activate: openTableSizes,
  } = useControl();

  const {
    state: isFastOrderOpened,
    deactivate: closeFastOrder,
    activate: openFastOrder,
  } = useControl();

  const [initialImageId, setInitialImageId] = useState(0);
  const [active, setActive] = useState<number>();
  const [activeOneSize, setActiveOneSize] = useState<number>();
  const [hoverable, setHoverable] = useState<number>();
  const [isCheckout, setIsCheckout] = useState(isInBasket || false);
  const [isFavourite, setIsFavourite] = useState(isInFavourite || false);
  const [isHelpVisible] = useState(false);
  const [isAvaliable1C, setAvaliable1C] = useState(true);
  const location = useLocation();
  const offer = useMemo(
    () =>
      !activeOneSize
        ? availableOffers.find((item) => Number(item.size_id) === active)
        : availableOffers[0],
    [availableOffers, active]
  );

  const fastProduct = [
    {
      name: product.name,
      id: product.id,
      new_price: product.new_price,
      old_price: product.old_price,
      brand: product.brand.name,
      gallery: product.gallery,
      category: categories,
      size: offer?.size_name,
      quantity: 1,
    },
  ];

  const { setIsCartOpened } = useContext(CheckoutContext);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProductQuantity(id, checkoutId)
      .then(({ data }) => {
        if (data.success) {
          const filteredOffers = filterAvailableOffersByQuantity(
            data,
            availableOffers
          );

          setAvailableOffers(filteredOffers);
        } else {
          setAvailableOffers([]);
        }
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let isFirstFind = false;
    sizes.forEach((size, i) => {
      const offer = availableOffers.find(
        (item) => Number(item.size_id) === size.id
      );
      if (offer && offer.quantity_shops?.length && !isFirstFind) {
        isFirstFind = true;
        onChooseSize(Number(offer.size_id));
        setActive(Number(offer.size_id));
        setHoverable(Number(offer.size_id));
      }
    });
    if (!sizes.length && availableOffers.length === 1) {
      setActiveOneSize(availableOffers[0].id);
    }
  }, [sizes]);

  const toggleActive = useCallback(
    (num: number) => (): void => {
      onChooseSize(num);
      setActive(num);
      setHoverable(num);
    },
    [setActive, setHoverable]
  );

  const toggleHoverable = useCallback(
    (num?: number) => (): void => {
      if (active === undefined) {
        setHoverable(num);
      }
    },
    [active, setHoverable]
  );

  const handleOpenImage = useCallback(
    (idx: number) => () => {
      setInitialImageId(idx);
      openImage();
    },
    [openImage, setInitialImageId]
  );

  const addItemToFavorites = (productId: number, offerId?: number) => {
    if (checkoutId && userWorkFlow) {
      let eventName = "";
      if (userWorkFlow === "authenticated") {
        setIsFavourite((prevState) => !prevState);
        addFavoriteProductWithBasketUuid(
          productId,
          checkoutId,
          offerId
        ).then(() =>
          getCheckout(checkoutId).then(({ data }) =>
            dispatch(setWishlistUuid(data.wish_list))
          )
        );
        eventName = isFavourite ? "remove-wishlist-item" : "add-wishlist-item";
      } else {
        setIsFavourite((prevState) => !prevState);
        const favoriteItems = getFavoritesFromLocalStorage();
        const items = new Set<LocalStorageFavoriteItem>(favoriteItems);
        const newFavoriteItem: LocalStorageFavoriteItem = {
          product_id: productId,
          offer_id: offerId,
          created_at: new Date().toISOString(),
        };
        const existingItem = Array.from(items).find(
          (item) => item.product_id === newFavoriteItem.product_id
        );
        if (existingItem) {
          items.delete(existingItem);
        } else {
          items.add(newFavoriteItem);
        }
        setFavoritesToLocalStorage(Array.from(items));

        const favorites = getFavoritesFromLocalStorage();
        if (favorites) {
          dispatch(setWishlistNoUuid(favorites));
        }
        eventName = isFavourite ? "remove-wishlist-item" : "add-wishlist-item";
      }
      const vendorCode = `${product.id_1c}#${size1c() ? size1c() : "ONE_SIZE"}`;
      sendTagManagerFavoritesData(eventName, vendorCode, productId);
    }
  };

  function size1c() {
    if (availableOffers.length) {
      return activeOneSize
        ? availableOffers.find(
            (
              i: any //  eslint-disable-line @typescript-eslint/no-explicit-any
            ) => +i.size_id === activeOneSize && i.available
          )?.size_id_1c
        : availableOffers.find((i) => i.size_id_1c && i.active)?.size_id_1c;
    }
    return "ONE_SIZE";
  }

  const handleAddToCheckout = useCallback(
    (toggleCheckout) => {
      const offer = availableOffers.find(
        (item) => Number(item.size_id) === active
      );
      const offerId = offer?.id || activeOneSize;

      if (getBasketId() !== checkoutId) {
        (async () => {
          try {
            await updateUser(dispatch);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        })();
      }

      if (!!offerId && !!checkoutId) {
        setLoading(true);
        setIsCheckout(true);
        if (checkoutId === "undefined" || checkoutId === null) {
          addToCheckoutWithoutUuid({
            product_id: product.id,
            quantity: 1,
            offer_id: offerId,
          })
            .then(({ data }) => {
              setBasketId(data.basket_uuid);
              dispatch(
                setCurrentWorkFlow({
                  userWorkFlow: "hasUuid",
                })
              );
              getCheckout(data.basket_uuid, true).then(({ data }) => {
                dispatch(setCheckout(data));
                if (window.matchMedia("(min-width: 768px)").matches) {
                  toggleCheckout(true);
                }
                sendTagManagerAddToCheckoutData({
                  productId: product.id,
                  vendorCode: `${product.id_1c}#${
                    size1c() ? size1c() : "ONE_SIZE"
                  }`,
                  productName: product.name,
                  productPrice: product.new_price,
                  productBrand: product.brand.name,
                  category: categories,
                  is_retail: userData?.is_retail ?? false,
                });
              });
            })
            .finally(() => setLoading(false));
        } else {
          addToCheckout(checkoutId, {
            product_id: product.id,
            basket_uuid: checkoutId,
            quantity: 1,
            offer_id: offerId,
          })
            .then(() => {
              getCheckout(checkoutId, true).then(({ data }) => {
                dispatch(setCheckout(data));
                if (window.matchMedia("(min-width: 768px)").matches) {
                  toggleCheckout(true);
                }
                sendTagManagerAddToCheckoutData({
                  productId: product.id,
                  vendorCode: `${product.id_1c}#${
                    size1c() ? size1c() : "ONE_SIZE"
                  }`,
                  productName: product.name,
                  productPrice: product.new_price,
                  productBrand: product.brand.name,
                  category: categories,
                  is_retail: userData?.is_retail ?? false,
                });
              });
            })
            .catch((error) => {
              if (error.response.status === 400) {
                addToCheckoutWithoutUuid({
                  product_id: product.id,
                  quantity: 1,
                  offer_id: offerId,
                }).then(({ data }) => {
                  setBasketId(data.basket_uuid);
                  getCheckout(data.basket_uuid, true).then(({ data }) => {
                    dispatch(setCheckout(data));
                    if (window.matchMedia("(min-width: 768px)").matches) {
                      toggleCheckout(true);
                    }
                    sendTagManagerAddToCheckoutData({
                      productId: product.id,
                      vendorCode: `${product.id_1c}#${
                        size1c() ? size1c() : "ONE_SIZE"
                      }`,
                      productName: product.name,
                      productPrice: product.new_price,
                      productBrand: product.brand.name,
                      category: categories,
                      is_retail: userData?.is_retail ?? false,
                    });
                  });
                });
              }
            })
            .finally(() => setLoading(false));
        }
      }
    },
    [
      active,
      activeOneSize,
      categories,
      checkoutId,
      dispatch,
      availableOffers,
      product,
    ]
  );

  const handleCopyLink = () => {
    const link = "https://leform.ru" + location.pathname;
    navigator.share({
      url: link,
      title: "Поделиться товаро в leform",
    });
  };

  function yaPay() {
    const YaPay = window.YaPay;
    if (YaPay) {
      YaPay.mountBadge(document.querySelector("#button_container"), {
        type: `${product.new_price < 150000 ? "bnpl" : "cashback"}`,
        amount: product.new_price,
        size: "m",
        variant: "detailed",
        color: "primary",
        merchantId: "e8e22170-3758-4549-84ea-c80340e9ca98",
      });
    }
  }

  useEffect(() => {
    const isOfferAvailable = Boolean(
      items.find(({ size }) => size && size.id === active)
    );
    setIsCheckout(isOfferAvailable);
  }, [active]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://pay.yandex.ru/sdk/v1/pay.js";
    script.async = true;
    script.onload = yaPay;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (product.id && checkoutId && userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        addLastViewsProductsByUuid(checkoutId, product.id);
      } else {
        let existingItems = getLastViewsFromLocalStorage() || [];
        const index = existingItems.indexOf(product.id.toString());
        if (index > -1) {
          existingItems.splice(index, 1);
        }
        existingItems.push(product.id.toString());
        if (existingItems.length > 10) {
          existingItems = existingItems.slice(1);
        }
        setLastViewsToLocalStorage(existingItems);
      }
    }
  }, [checkoutId, product.id, userWorkFlow]);

  useEffect(() => {
    if (offer) {
      yaPay();
    }
    return () => {
      window.YaPay = undefined;
    };
  }, [offer, yaPay]);
  const breadCrumbsJsx = (
    <>
      <li
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
        <Link to="/" itemProp="item">
          <span itemProp="name">Leform</span>
        </Link>
        <meta itemProp="position" content="1" />
        <Slash>/</Slash>
      </li>

      {section_tree && section_tree.length > 0 ? (
        <>
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link to="/catalog" itemProp="item">
              <span itemProp="name">Каталог</span>
            </Link>
            <meta itemProp="position" content="2" />
            <Slash>/</Slash>
          </li>

          {section_tree.map(
            (item: SectionTree, idx: number, array: SectionTree[]) => {
              return (
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  key={"bread_" + item.id}
                >
                  <Fragment>
                    <Link to={`/catalog/${item.url}`} itemProp="item">
                      <span itemProp="name">{item.name}</span>
                    </Link>
                    <Slash>/</Slash>
                  </Fragment>
                  <meta itemProp="position" content="3" />
                </li>
              );
            }
          )}
        </>
      ) : (
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <CategoryName itemProp="name">Каталог</CategoryName>
          <meta itemProp="position" content="2" />
        </li>
      )}
    </>
  );

  const breadCrumbsMobileJsx = section_tree.map(
    (item: SectionTree, idx: number, array: SectionTree[]) => {
      if (idx === array.length - 1) {
        return (
          <Fragment key={item.id}>
            <Link to={`/catalog/${item.url}`}>
              <PrevArrow />
              {item.name}
            </Link>
          </Fragment>
        );
      }
      return true;
    }
  );

  const sizeOutput = useCallback(
    (size: ProductSizes) => {
      if (size.size_alt && size.size_alt !== size.name) {
        return (
          <>
            {size.size_alt} ({size.name})
          </>
        );
      } else {
        return size.name;
      }
    },
    [sizes]
  );

  const offerSizeOutput = useCallback(
    (offer: ProductOffer) => {
      if (offer.size_alt && offer.size_alt !== offer.size_name) {
        return (
          <>
            {offer.size_alt} ({offer.size_name})
          </>
        );
      } else if (offer.size_name) {
        return <>{offer.size_name}</>;
      } else {
        return "";
      }
    },
    [offer]
  );

  const getActionMessage = useMemo(() => {
    return action?.default ? action.default : "";
  }, [action]);

  const isGreenActionMessage = useMemo(() => {
    if (
      getActionMessage ===
      "Используйте промокод <b>BBS</b>, чтобы получить дополнительную скидку -30%"
    ) {
      return true;
    } else {
      return false;
    }
  }, [getActionMessage]);

  useEffect(() => {
    setAvaliable1C(
      checkout.items.filter(
        (checkoutItem) =>
          !checkoutItem.in_stock &&
          checkoutItem.product.id === item?.product.id &&
          ((checkoutItem.size && checkoutItem.size.id === active) ||
            !checkoutItem.size)
      ).length === 0
    );
  }, [checkout, active]);

  useEffect(() => {
    if (!isAvaliable1C && sizes.length > 1) {
      setActive(
        sizes.filter(
          (size) =>
            checkSizeAvailability(size.id, availableOffers) &&
            size.id !== active
        )[0].id
      );
    }
  }, [isAvaliable1C, sizes, availableOffers]);

  return (
    <>
      <BreadCrumbs itemScope itemType="http://schema.org/BreadcrumbList">
        {breadCrumbsJsx}
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <CategoryName itemProp="name">
            {productType.name} {brand.name}
          </CategoryName>
          <meta itemProp="position" content="4" />
        </li>
      </BreadCrumbs>
      <BreadCrumbsMobile>{breadCrumbsMobileJsx}</BreadCrumbsMobile>
      <Container
        itemProp={brand.name + productType.name}
        itemScope
        itemType="https://schema.org/Product"
        id="info_product"
      >
        <MobileHeaderContainer>
          <Name>
            <Kind>
              <Link to={`/catalog/type/${productType.code}/`}>
                {productType.name}
              </Link>
            </Kind>
            <BrandContainer>
              <Link to={`/brands/${brand.code}/`}>{brand.name}</Link>
              <ButtonsContainer>
                <ShareButton onClick={handleCopyLink}>
                  <Icons.Share />
                </ShareButton>
                <FavoriteButtonProduct
                  onClick={() => {
                    if (offer) {
                      addItemToFavorites(product.id, offer.id);
                    } else {
                      addItemToFavorites(product.id);
                    }
                  }}
                  variant="white"
                  aria-label="Добавить в избранное"
                  isFavourite={isFavourite}
                  isHelpVisible={isHelpVisible}
                >
                  <HeartIcon />
                </FavoriteButtonProduct>
              </ButtonsContainer>
            </BrandContainer>
            <Article
              productName={product.product_name}
              productArticle={article}
            />
            <meta
              itemProp="name"
              content={productType.name + " " + brand.name}
            />
            <PriceContainer>
              {old_price > new_price && (
                <OldPrice>{formatCurrency(old_price)}</OldPrice>
              )}
              <Price>{formatCurrency(new_price)}</Price>
            </PriceContainer>
          </Name>
        </MobileHeaderContainer>
        <ImageContainerWrapper>
          <ImageContainer>
            {desktopImages.map((img, idx) => (
              <ImageButton
                onClick={handleOpenImage(idx)}
                key={`${img}-${idx}`}
                aria-label="Открыть изображение"
              >
                <Picture>
                  <source
                    srcSet={convertImageUrl(img, 760, 1120)}
                    media="(max-width: 1200px)"
                  />
                  <Image
                    itemProp="image"
                    src={convertImageUrl(img, 1200, 1340)}
                    alt={name}
                  />
                  {idx === 0 && (
                    <Labels className={catalogClassName}>
                      {!(new_price < old_price) && is_new && (
                        <LabelItem className={catalogClassName}>new</LabelItem>
                      )}
                      {is_super && <LabelItemLove>love</LabelItemLove>}
                      {is_promo && (
                        <LabelItemPromo className={catalogClassName}>
                          -20% по промокоду HEAT
                        </LabelItemPromo>
                      )}
                      {(!is_new || new_price < old_price) &&
                        is_blacksale &&
                        !is_finalsale &&
                        !is_super && (
                          <LabelItemBlackSmall className={catalogClassName}>
                            sale
                          </LabelItemBlackSmall>
                        )}
                      {!is_super &&
                        !is_archive &&
                        !is_finalsale &&
                        new_price < old_price &&
                        !is_blacksale && (
                          <LabelItemSale className={catalogClassName}>
                            sale
                          </LabelItemSale>
                        )}
                      {is_finalsale &&
                        !is_super &&
                        !is_archive &&
                        !is_blacksale && (
                          <LabelItemBlack className={catalogClassName}>
                            Black Week
                          </LabelItemBlack>
                        )}
                    </Labels>
                  )}
                </Picture>
              </ImageButton>
            ))}
          </ImageContainer>
          <DescriptionWrapper className="not-mobile">
            <Description item={item} deliveryInfo={deliveryInfo} />
          </DescriptionWrapper>
        </ImageContainerWrapper>
        <ImageCarousel nextArrow={<></>} prevArrow={<></>}>
          {images.map((img, idx) => (
            <ImageButton
              onClick={handleOpenImage(idx)}
              key={`${img}-${idx}`}
              aria-label="Открыть изображение"
            >
              <Picture>
                <Image src={convertImageUrl(img, 800, 1200)} alt={name} />
                <Labels className={catalogClassName}>
                  {!(new_price < old_price) && is_new && (
                    <LabelItem className={catalogClassName}>new</LabelItem>
                  )}
                  {is_super && <LabelItemLove>love</LabelItemLove>}
                  {(is_new || new_price < old_price) &&
                    is_archive &&
                    is_super && (
                      <LabelItemBlack className={catalogClassName}>
                        archive sale
                      </LabelItemBlack>
                    )}
                  {!is_super && !is_archive && new_price < old_price && (
                    <LabelItemSale className={catalogClassName}>
                      sale
                    </LabelItemSale>
                  )}
                </Labels>
              </Picture>
            </ImageButton>
          ))}
        </ImageCarousel>
        <InfoContainer>
          <StickyContainer>
            <HeaderContainer>
              <NameContainer>
                <Name>
                  <Kind>
                    <Link to={`/catalog/type/${productType.code}/`}>
                      {productType.name}
                    </Link>
                  </Kind>
                  <BrandContainer>
                    <Link to={`/brands/${brand.code}/`}>{brand.name}</Link>
                    <FavoriteButtonProduct
                      onClick={() => {
                        if (offer) {
                          addItemToFavorites(product.id, offer.id);
                        } else {
                          addItemToFavorites(product.id);
                        }
                      }}
                      variant="white"
                      aria-label="Добавить в избранное"
                      isFavourite={isFavourite}
                      isHelpVisible={isHelpVisible}
                    >
                      <HeartIcon />
                    </FavoriteButtonProduct>
                  </BrandContainer>
                  <Article
                    productName={product.product_name}
                    productArticle={article}
                  />
                  <meta
                    itemProp="name"
                    content={productType.name + " " + brand.name}
                  />
                  <PriceContainer
                    itemProp="offers"
                    itemScope
                    itemType="https://schema.org/Offer"
                  >
                    {old_price > new_price && (
                      <OldPrice>{formatCurrency(old_price)}</OldPrice>
                    )}
                    <Price>{formatCurrency(new_price)}</Price>
                    <meta itemProp="price" content={new_price.toString()} />
                    <link
                      itemProp="availability"
                      href="https://schema.org/InStock"
                    />
                    <meta itemProp="priceCurrency" content="RUB" />
                  </PriceContainer>
                </Name>
                <meta itemProp="description" content={detail_text} />
              </NameContainer>
              {getActionMessage && (
                <Promotion
                  isGreen={isGreenActionMessage}
                  isFinalSale={product.is_finalsale}
                  isLove={product.is_super}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: getActionMessage }}
                  />
                </Promotion>
              )}
            </HeaderContainer>
            {sizes.length > 0 && (
              <SizeContainer>
                <SizeTitle>Размеры</SizeTitle>
                <SizeButtonContainer child={hoverable}>
                  {sizes.map((size) => {
                    if (
                      checkSizeAvailability(size.id, availableOffers) &&
                      checkout.items.filter(
                        (checkoutItem) =>
                          !checkoutItem.in_stock &&
                          checkoutItem.product.id === item?.product.id &&
                          ((checkoutItem.size &&
                            checkoutItem.size.id === size.id) ||
                            !checkoutItem.size)
                      ).length === 0
                    ) {
                      return (
                        <SizeButton
                          key={size.id}
                          variant="white"
                          isActive={active === size.id}
                          onClick={toggleActive(size.id)}
                          onMouseEnter={toggleHoverable(size.id)}
                          onMouseLeave={toggleHoverable()}
                        >
                          {sizeOutput(size)}
                        </SizeButton>
                      );
                    }
                    return true;
                  })}
                </SizeButtonContainer>

                <ShowTableSizes onClick={openTableSizes}>
                  Таблица размеров
                </ShowTableSizes>
              </SizeContainer>
            )}
            {another_colors && another_colors.length ? (
              <ProductColorContainer another_colors={another_colors} />
            ) : (
              <></>
            )}
            <ButtonContainer>
              {isAvailable && isAvaliable1C ? (
                <CheckoutButton
                  onClick={() => {
                    handleAddToCheckout(setIsCartOpened);
                  }}
                  disabled={isLoading || (!active && !activeOneSize)}
                >
                  {!isCheckout ? (
                    <>
                      В корзину
                      <span>+</span>
                    </>
                  ) : (
                    <>Добавлено</>
                  )}
                </CheckoutButton>
              ) : (
                <EmptyButton>Нет в наличии</EmptyButton>
              )}

              <QuickOrder
                variant="white"
                onClick={openFastOrder}
                disabled={!isAvailable}
              >
                Быстрый заказ
              </QuickOrder>
            </ButtonContainer>
            <div id="button_container"></div>
            {getActionMessage && (
              <PromotionMobile>
                <span dangerouslySetInnerHTML={{ __html: getActionMessage }} />
              </PromotionMobile>
            )}
            <ProductDelivery isMsk={is_msk} offer={offer} />
            <AvailabilityContainer
              id="markets"
              className={is_msk ? "msk" : "not-msk"}
            >
              {isLoading && <Loader />}
              {!isLoading && isAvailable && (
                <Accordeon
                  header={
                    <AvailabilityTitle>Наличие в магазинах</AvailabilityTitle>
                  }
                  content={
                    <AvailabilityList>
                      {offer &&
                        offer.quantity_shops?.map(({ count, shop }) => (
                          <AvailabilityItem key={shop}>
                            <AvailabilityLink to={ROUTES.shops}>
                              {shop}
                            </AvailabilityLink>
                            <AvailabilityInnerWrapper>
                              <AvailabilitySize>
                                {offerSizeOutput(offer)}
                              </AvailabilitySize>
                              <AvailabilityCount>
                                {count <= 3 ? count : 3} шт.
                              </AvailabilityCount>
                            </AvailabilityInnerWrapper>
                          </AvailabilityItem>
                        ))}
                    </AvailabilityList>
                  }
                />
              )}
            </AvailabilityContainer>
            <ProductCategoriesWrapper className="not-mobile">
              <ProductCategories links={links} />
            </ProductCategoriesWrapper>
          </StickyContainer>
        </InfoContainer>
      </Container>

      {isImageOpened && (
        <ImageModal
          onClose={closeImage}
          images={images}
          initialImageId={initialImageId}
        />
      )}
      {isErrorModalOpen && (
        <ProductNotAvailableModal onClose={() => setIsErrorModalOpen(false)} />
      )}
      {isTableSizesOpened && (
        <TableSizesModal
          onClose={closeTableSizes}
          head={sizes_table.head}
          sizes={sizes_table.sizes}
          sizeName={offer?.size_name}
          preview={preview_text}
          detail={detail_text}
          size_head={sizes_table.size_head}
        />
      )}

      {isFastOrderOpened && offer && (
        <FastOrder
          onClose={closeFastOrder}
          offerId={offer.id}
          fastProduct={fastProduct}
          price={product.new_price}
        />
      )}

      <DescriptionWrapper className="mobile">
        <Description item={item} deliveryInfo={deliveryInfo} />
      </DescriptionWrapper>
      <ProductCategoriesWrapper className="mobile">
        <ProductCategories links={links} />
      </ProductCategoriesWrapper>
    </>
  );
};

export default Product;
