import { Magnifier } from "react-image-magnifiers";
import styled from "styled-components";

import { Carousel } from "../Carousel";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";

import { ThumbnailProps } from "./ImageModal.types";

type ImageZoomType = {
  zoom: boolean;
};

export const ModalContainer = styled(Modal)<ImageZoomType>`
  max-width: 100%;
  height: ${({ zoom }) => (zoom ? "auto" : "100%")};
`;

export const CloseButton = styled(IconButton)`
  position: fixed;
  z-index: 2;
  top: 38px;
  right: 38px;
  background-color: transparent;

  :hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 1150px) {
    top: 24px;
    right: 24px;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 28px;
  height: 28px;

  @media screen and (max-width: 1150px) {
    width: 24px;
    height: 24px;
  }
`;

export const Picture = styled.picture<ImageZoomType>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
export const ImageWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1900px;
  max-height: ${({ isMobile }) =>
    isMobile ? "calc(100vh - 50px)" : "max-content"};
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
`;

export const MagnifierCarousel = styled(Carousel)`
  max-height: calc(100vh - 40px);
`;

export const MagnifierWrapper = styled(Magnifier)`
  position: relative;
  overflow: hidden;
  width: 100%;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 40px);
    @media screen and (max-width: 768px) {
      height: calc(100vh - 50px);
    }
    > img {
      width: fit-content !important;
      max-width: 500px;
      height: calc(100vh - 40px);
      max-height: calc(100vh - 40px);
      object-fit: cover;
      cursor: zoom-in;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
`;

export const MagnifierContainer = styled.div`
  width: 100%;
  max-width: 100vw;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: calc(100vh - 40px);
`;

export const Image = styled.img<ImageZoomType>`
  height: ${({ zoom }) => (zoom ? "auto" : "100%")};
  width: ${({ zoom }) => (zoom ? "100%" : "auto")};
  min-height: 100vh;
  object-fit: cover;
  display: block;
  cursor: ${({ zoom }) => (zoom ? "zoom-out" : "zoom-in")};
`;

export const ThumbnailList = styled.ul`
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 2;
  top: 12%;
  left: 5%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

export const Thumbnail = styled.li<ThumbnailProps>`
  margin: 0 0 12px;
  padding: 0;
  cursor: pointer;
  outline-offset: 2px;
  outline: ${({ isActive }): string => (isActive ? "1px" : "0px")} solid black;
`;

export const ThumbnailImage = styled.img`
  display: block;
  width: 55px;
  height: auto;
  object-fit: cover;
`;

export const MockContainerClick = styled.div<{
  disabled: boolean;
  isBottom: boolean;
}>`
  position: fixed;
  width: 100%;
  height: 50%;
  display: ${({ disabled }) => (disabled ? "none" : "block")};
  background-color: transparent;
  ${({ isBottom }) => (isBottom ? "bottom: 0px;" : "top: 0px;")}
`;
